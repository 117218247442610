import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import Footer from "../components/footer";
import { connect } from "react-redux";
import { authPasswordChangeInit } from "../redux/auth/actions";
import SEO from "../components/seo";
import { useIntl, navigate } from "gatsby-plugin-intl";
import BodyClassName from "react-body-classname";
import withPathPairs from "../hocs/withPathPairs";
import { appRedirect } from "../redux/app/actions";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Tools from "../classes/tools";

const ModificarContrasena = ({ passwordChangeDone, authPasswordChangeInit, urlVars, appRedirect, s }) => {
	const intl = useIntl();

	const [key, setKey] = useState("");
	const [password1, setPassword1] = useState("");
	const [password2, setPassword2] = useState("");

	useEffect(() => {
		if (urlVars.key) setKey(urlVars.key);
		else appRedirect({ to: "/" });
	}, []);

	useEffect(() => {
		if (passwordChangeDone) {
			appRedirect({ to: "/" });
		}
	}, [passwordChangeDone]);

	const handleSubmit = (e) => {
		e.preventDefault();
		authPasswordChangeInit(intl.locale, {
			key,
			password1,
			password2,
		});
	};

	const images = useStaticQuery(graphql`
		query {
			contact: file(relativePath: { eq: "contact.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	const onHeaderGeoLocationChange = (data) => {
		navigate(
			`/anuncios/mode/geo/lat/${data.lat}/long/${data.long}/geo_descripcion/${data.descripcion}/orden/distancia`
		);
	};

	return (
		<>
			<BodyClassName className="contact">
				<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
					<SEO title={Tools.convertString(s.modificarcontrasena?.title)} />
					<main className="page form-page">
						{/* STEP 01 */}
						<div className="steps-wrapper">
							<article className="form-box box">
								<div className="wrapper">
									<h4 className="black-text-01 prequel-font">{Tools.convertString(s.modificarcontrasena?.title)}</h4>
									<p className="black-text-01">{Tools.convertString(s.modificarcontrasena?.subtitle)}</p>
									<form onSubmit={handleSubmit}>
										<fieldset>
											<label className="black-text-01" htmlFor="password1">
												<input
													className="input black-text-03"
													placeholder={Tools.convertString(s.modificarcontrasena?.password1)}
													name="password1"
													id="password1"
													type="password"
													value={password1}
													onChange={(e) => setPassword1(e.target.value)}
												/>
												<span className="black-text-01">{Tools.convertString(s.modificarcontrasena?.password1)}</span>
											</label>
										</fieldset>
										<fieldset>
											<label className="black-text-01" htmlFor="password2">
												<input
													className="input black-text-03"
													placeholder={Tools.convertString(s.modificarcontrasena?.password2)}
													name="password2"
													id="password2"
													type="password"
													value={password2}
													onChange={(e) => setPassword2(e.target.value)}
												/>
												<span className="black-text-01">{Tools.convertString(s.modificarcontrasena?.password2)}</span>
											</label>
										</fieldset>
										<div className="button-wrapper">
											<button type="submit" className="button black-bg-03 white-text">
												{Tools.convertString(s.modificarcontrasena?.enviar)}
											</button>
										</div>
									</form>
								</div>
							</article>
							<article className="form-img show-lg">
								<picture>
									<Img fluid={images.contact.childImageSharp.fluid} alt={``} />
								</picture>
							</article>
						</div>
						{/* /STEP 01 */}
						<Footer />
					</main>
				</Layout>
			</BodyClassName>
		</>
	);
};

const mapStateToProps = (state) => {
	const { passwordChangeDone } = state.app.events;
	return {
		passwordChangeDone,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	authPasswordChangeInit,
	appRedirect,
};

export default connect(mapStateToProps, mapDispatchToProps)(withPathPairs(ModificarContrasena));
